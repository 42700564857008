@import '../../../../sass/vars';

#root-home .section-involve {
  position: relative;
  padding: 50px 0 0;
  z-index: 2;

  @media (max-width: 1023px) {
    padding: 75px 0 0;
  }

  &::before {
    content: '';
    background-color: #eef2ff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 52px;
    left: 0;
    z-index: 1;

    @media (max-width: 1023px) {
      bottom: 20px;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &-description {
      position: relative;
      width: 425px;
      z-index: 1;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
      }

      p {
        background-image: linear-gradient(to right, #2e63f9, #6bbcff);
        font-size: 32px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.02em;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        strong {
          background-image: linear-gradient(to right, #00289a, #00289a);
          color: #00289a;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }

      &::before {
        content: '';
        background-image: linear-gradient(to right, #2e63f9, #6bbcff);
        position: absolute;
        top: 43px;
        left: calc(100% + 25px);
        width: 340px;
        height: 2px;
        transform-origin: 0% 50%;
        transform: scaleX(0);
        transition-property: transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          width: 100%;
          left: calc(100% + 10px);
        }

        @media (max-width: 767px) {
          display: none;
          left: calc(100% + 100px);
        }
      }

      &.appear::before {
        transform: scaleX(1);
        transition-delay: 0.5s;
      }
    }

    &-title {
      background-color: #eef2ff;
      color: #2e63f9;
      font-size: 52px;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      padding-left: 30px;
      z-index: 2;

      @media (max-width: 1023px) {
        width: 100%;
        text-align: left;
        font-size: 32px;
        padding: 0;
      }
    }
  }

  .content {
    position: relative;
    width: 972px;
    height: 476px;
    margin: 55px auto 0;

    @media (max-width: 1023px) {
      width: 828px;
      max-width: 100%;
      height: auto;
    }

    img {
      display: block;

      @media (max-width: 1023px) {
        width: auto;
        max-width: 100%;
      }
    }

    &-image--1 {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;

      @media (max-width: 1023px) {
        position: relative;
      }

      &_element {
        position: relative;
        z-index: 1;
      }

      &_video,
      &_gif {
        background-color: black;
        position: absolute;
        top: 20px;
        left: 94px;
        right: 89px;
        bottom: 51px;
        z-index: 2;

        @media (max-width: 1023px) {
          top: 4%;
          right: 11%;
          bottom: 9%;
          left: 11.5%;
        }

        img,
        video {
          @extend %cover;
        }
      }
    }

    &-image--2 {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;

      @media (max-width: 1023px) {
        display: none;
      }

      &_element {
        position: relative;
        z-index: 1;
      }

      &_video {
        background-color: white;
        position: absolute;
        top: 7px;
        left: 7px;
        right: 8px;
        bottom: 7px;
        border-radius: 16px;
        overflow: hidden;
        z-index: 2;

        &::after {
          content: '';
          background-color: black;
          position: absolute;
          top: 4px;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 13px;
          border-radius: 50px;
          z-index: 2;
        }

        video {
          @extend %cover;

          z-index: 1;
        }
      }
    }
  }
}
