@import '../../../sass/vars';

.section-general-cta {
  background-image: linear-gradient(
    102.34deg,
    #00289a 0.24%,
    #2e63f9 36.27%,
    #ffc666 100%
  );
  position: relative;
  padding: 180px 0 60px;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .privacy {
    margin-top: 15px;
    font-size: 12px;
    color: #7785b5;

    a {
      text-decoration: none;
    }
  }

  .background {
    @extend %cover;

    pointer-events: none;
    user-select: none;
    z-index: 1;

    @media (max-width: 1023px) {
      display: none;
    }

    img {
      @extend %cover;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    .left {
      width: 360px;

      @media (max-width: 1023px) {
        width: 100%;
      }

      &-title {
        color: #ffc666;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.02em;

        @media (max-width: 1023px) {
          font-size: 28px;
        }
      }

      &-description {
        margin: 25px 0 50px;
        padding-bottom: 50px;
        position: relative;

        @media (max-width: 1023px) {
          margin: 15px 0 25px;
          padding-bottom: 30px;
        }

        &::after {
          content: '';
          background-image: linear-gradient(to right, #2e63f9, #aa83ff);
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 1px;
        }

        p {
          color: white;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.4;
          opacity: 0.8;
        }
      }

      &-small {
        color: white;
        font-size: 16px;
        font-weight: 600;
      }

      &-cta {
        margin-top: 25px;

        a {
          background-color: transparent;
          border: 1px solid white;
          border-radius: 8px;
          padding: 0 24px;
          height: 50px;
          display: inline-flex;
          align-items: center;
          transition-property: background-color, border-color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          span {
            margin-right: 10px;
            color: white;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            transition-property: color;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
          }

          svg path {
            transition-property: fill;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
          }

          &:hover {
            background-color: #59d674;
            border-color: #59d674;

            span {
              color: white;
            }

            svg path {
              fill: white;
            }
          }
        }
      }
    }

    .right {
      @media (max-width: 1023px) {
        margin-top: 50px;
        width: 100%;
      }

      &-form {
        background-color: white;
        width: 720px;
        padding: 60px;
        border-radius: 8px;

        @media (max-width: 767px) {
          width: 100%;
          padding: 30px 25px;
        }

        .field-group {
          position: relative;

          .label {
            margin-bottom: 8px;
            color: #7785b5;
            font-size: 10px;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 0.08em;
          }

          .field {
            background-color: #eef2ff;
            color: #7785b5;
            font-size: 14px;
            font-weight: 600;
            width: 100%;
            height: 44px;
            padding: 0 16px;
            border: 1px solid transparent;
            border-radius: 8px;
            outline: none;
            transition-property: color, background-color, border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &::placeholder {
              color: fade-out(#7785b5, 0.6);
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:focus {
              border-color: #2e63f9;
            }
          }

          select.field {
            appearance: none;
            color: fade-out(#7785b5, 0.6);
          }

          textarea.field {
            padding: 16px;
            min-height: 160px;
            resize: none;
          }

          svg {
            position: absolute;
            bottom: 16px;
            right: 19px;
            pointer-events: none;
          }

          &.invalid .field {
            background-color: fade-out(red, 0.8);
            color: fade-out(black, 0.4);

            &::placeholder {
              color: fade-out(black, 0.4);
            }
          }
        }

        .form-flex {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 30px;
          grid-row-gap: 30px;
          margin-bottom: 30px;

          @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 0;
          }
        }

        .form-submit {
          margin-top: 30px;
          text-align: right;
        }

        button {
          background-image: linear-gradient(
            90deg,
            #2e63f9 0%,
            #1997ff 101.58%
          );
          border-radius: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          padding: 0 24px;

          span {
            margin-right: 10px;
            color: white;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0.08em;
            text-transform: uppercase;
          }

          svg {
            transition-property: transform;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
          }

          &:hover {
            svg {
              transform: translate(3px, -3px);
            }
          }
        }
      }
    }
  }
}
