@import '../../../../sass/vars';

#page-blog .section-articles {
  background-color: #eef2ff;
  padding: 70px 0 105px;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .headline {
    padding: 0 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }

    &-left {
      @media (max-width: 1023px) {
        width: 100%;
      }

      &--form {
        position: relative;
        width: 515px;
        display: flex;
        align-items: center;

        @media (max-width: 1023px) {
          width: 100%;
        }

        .field-group {
          width: 100%;
          position: relative;
          z-index: 1;

          .field {
            background-color: transparent;
            color: #2e63f9;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            padding: 0 20px 0 40px;
            height: 44px;
            border: 1px solid #d2ddfe;
            border-radius: 8px;
            appearance: none;

            &::placeholder {
              color: #2e63f9;
            }
          }
        }

        .form-submit {
          position: absolute;
          bottom: 11px;
          left: 15px;
          z-index: 2;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;

      @media (max-width: 1023px) {
        width: 100%;
        margin-top: 50px;
        flex-direction: column;
        justify-content: center;
      }

      &--title {
        color: #94affc;
        font-size: 14px;
        font-weight: 500;
        margin-right: 15px;

        @media (max-width: 1023px) {
          margin: 0 0 15px;
        }
      }

      &--navigation {
        ul {
          display: flex;
          align-items: center;

          @media (max-width: 1023px) {
            justify-content: center;
          }

          li {
            &:not(:last-child) {
              margin-right: 22px;

              @media (max-width: 1023px) {
                margin-right: 18px;
              }
            }

            a {
              color: #2e63f9;
              font-size: 14px;
              font-weight: bold;
              transition-property: color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              &.active {
                color: #292936;
              }

              @media (min-width: 1024px) {
                &:hover {
                  color: #292936;
                }
              }
            }
          }
        }
      }
    }
  }

  .flex-wrapper {
    // margin-top: 50px;
    padding: 0 55px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 120px;
    grid-row-gap: 95px;

    @media (max-width: 1023px) {
      grid-column-gap: 30px;
      padding: 0;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .card {
      position: relative;

      &-link {
        @extend %cover;

        z-index: 5;
      }

      &-block {
        position: relative;
        width: 100%;
        height: 330px;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        z-index: 1;

        @media (max-width: 1023px) {
          height: auto;
          aspect-ratio: 1 / 0.75;
        }

        &::after {
          @extend %cover;

          content: '';
          background: linear-gradient(
            180deg,
            rgba(238, 242, 255, 0) 32.18%,
            rgba(210, 221, 255, 0.87) 67.31%
          );
          z-index: 2;
        }

        &--image {
          transform: scale(1);
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }

        &--image,
        &--image img {
          @extend %cover;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 330px;
        padding: 25px 30px;
        z-index: 2;

        @media (max-width: 1023px) {
          height: auto;
          aspect-ratio: 1 / 0.75;
          padding: 20px;
        }

        &--flex {
          display: flex;
          align-items: center;

          .left {
            margin-right: 15px;

            &-title {
              color: black;
              font-size: 12px;
              font-weight: 800;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              display: block;
              background-color: #ffc666;
              border-radius: 80px;
              padding: 5px 20px;

              @media (max-width: 1023px) {
                font-size: 10px;
                padding: 5px 15px;
              }
            }
          }

          .right {
            &-date {
              color: #292936;
              font-size: 12px;
              font-weight: 800;
              letter-spacing: 0.08em;
              text-transform: uppercase;

              @media (max-width: 1023px) {
                font-size: 10px;
              }
            }
          }
        }

        &--title {
          color: #2e63f9;
          font-size: 28px;
          font-weight: 700;
          line-height: 1.2;
          letter-spacing: -0.02em;
          margin-top: 20px;

          @media (max-width: 1023px) {
            font-size: 21px;
            margin-top: 15px;
          }
        }
      }

      &-description {
        background-color: #ffffff;
        padding: 25px 30px 55px;
        border-radius: 0px 0px 8px 8px;

        @media (max-width: 1023px) {
          padding: 20px 20px 55px;
        }

        p {
          color: #7785b5;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.4;
        }
      }

      &-cta {
        position: absolute;
        left: 30px;
        bottom: -25px;

        @media (max-width: 1023px) {
          left: 20px;
        }

        a {
          background-image: linear-gradient(
            to right,
            #2e63f9,
            #1997ff,
            #2e63f9
          );
          background-size: 200% 100%;
          background-position: left center;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 0 25px;
          height: 50px;
          border-radius: 8px;
          transition-property: background-position;
          transition-duration: time(fast);
          transition-timing-function: eae(inout);

          span {
            color: white;
            font-size: 12px;
            font-weight: 800;
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            margin-right: 10px;
          }

          svg {
            transition-property: transform;
            transition-duration: time(fast);
            transition-timing-function: eae(inout);
          }
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          .card-block .card-block--image {
            transform: scale(1.05);
          }

          .card-cta a {
            background-position: right center;

            svg {
              transform: translate(5px, -5px);
            }
          }
        }
      }
    }
  }

  .pagination {
    margin-top: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    &-left {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 1px solid #becaed;
      }
    }

    &-center {
      margin: 0 45px;

      ul {
        display: flex;
        align-items: center;

        li {
          &:not(:last-child) {
            margin-right: 25px;
          }

          a {
            color: #00289a;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0.08em;

            &.active {
              color: #fba94a;
            }
          }
        }
      }
    }

    &-right {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #becaed;
        width: 56px;
        height: 56px;
        border-radius: 50%;

        svg path {
          stroke: #2e63f9;
        }
      }
    }
  }
}
