@import '../../../../sass/vars';

#page-blog .section-hero {
  background-color: #eef2ff;
  position: relative;
  height: 600px;
  z-index: 2;

  @media (max-width: 1023px) {
    height: 550px;
  }

  .slide {
    position: relative;
    width: 100%;
    height: 100%;

    &-items {
      @extend %cover;

      overflow: hidden;
      z-index: 1;

      .item {
        @extend %cover;

        z-index: 1;

        &.active {
          z-index: 2;

          .item-background {
            opacity: 1;
            transform: scale(1);
            display: block;
          }

          .item-wrapper {
            &--flex .left,
            &--flex .right,
            &--title,
            &--description,
            &--cta {
              opacity: 1;
              transform: translate(0, 0);
            }

            &--flex .left {
              transition-delay: 0.3s;
            }

            &--flex .right {
              transition-delay: 0.4s;
            }

            &--title {
              transition-delay: 0.5s;
            }

            &--description {
              transition-delay: 0.6s;
            }

            &--cta {
              transition-delay: 0.7s;
            }
          }
        }

        &-background {
          @extend %cover;

          background: linear-gradient(
            102.34deg,
            #00289a 0.24%,
            #2e63f9 36.31%,
            #ffc666 92.95%
          );
          opacity: 0;
          transform: scale(1.02);
          transition-property: opacity, transform;
          transition-duration: 1.5s;
          transition-timing-function: ease(inout);
          display: none;
          z-index: 1;

          &.loaded {
            display: block;
          }

          img {
            @extend %cover;

            mix-blend-mode: overlay;
          }
        }

        &-wrapper {
          position: relative;
          padding-top: 185px;
          z-index: 2;

          @media (max-width: 1023px) {
            padding-top: 130px;
          }

          &--flex {
            width: 420px;
            display: flex;
            align-items: center;

            .left {
              margin-right: 20px;
              opacity: 0;
              transform: translateX(10px);
              transition-property: opacity, transform;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &-title {
                background-color: #ffc666;
                border-radius: 80px;
                padding: 5px 20px;
                color: black;
                font-size: 12px;
                font-weight: 800;
                letter-spacing: 0.08em;
                text-transform: uppercase;

                @media (max-width: 1023px) {
                  font-size: 10px;
                  padding: 5px 15px;
                }
              }
            }

            .right {
              opacity: 0;
              transform: translateX(10px);
              transition-property: opacity, transform;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &-date {
                color: #b7deff;
                font-size: 12px;
                font-weight: 800;
                letter-spacing: 0.08em;
                text-transform: uppercase;

                @media (max-width: 1023px) {
                  font-size: 10px;
                }
              }
            }
          }

          &--title {
            width: 510px;
            margin-top: 12px;
            color: white;
            font-size: 36px;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: -0.02em;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            @media (max-width: 1023px) {
              font-size: 24px;
              width: 100%;
            }
          }

          &--description {
            width: 460px;
            margin-top: 25px;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            @media (max-width: 1023px) {
              width: 100%;
              margin-top: 15px;
            }

            p {
              color: white;
              font-size: 16px;
              font-weight: 600;
              line-height: 1.4;
              opacity: 0.8;

              @media (max-width: 1023px) {
                font-size: 14px;
              }
            }
          }

          &--cta {
            margin-top: 35px;
            opacity: 0;
            transform: translateY(10px);
            transition-property: opacity, transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            @media (max-width: 1023px) {
              margin-top: 25px;
            }

            a {
              background-color: transparent;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 50px;
              padding: 0 25px;
              border: 1px solid white;
              border-radius: 8px;
              transition-property: background-color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              span {
                margin-right: 10px;
                color: white;
                font-size: 12px;
                font-weight: 800;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                transition-property: color;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              svg {
                transition-property: transform;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              @media (min-width: 1024px) {
                &:hover {
                  background-color: white;

                  span {
                    color: #00289a;
                  }

                  svg {
                    transform: translate(3px, -3px);
                  }
                }
              }
            }
          }
        }
      }
    }

    &-arrows {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: -28px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      button {
        background-color: white;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 1px solid white;
        transition-property: background-color;
        transition-duration: time(fast);
        transition-timing-function: ease(inout);

        &:nth-child(1) {
          padding-right: 3px;
        }

        &:nth-child(2) {
          padding-left: 3px;
          margin-left: 15px;
        }

        svg path {
          stroke: #7785b5;
        }

        @media (min-width: 1024px) {
          &:hover {
            background-color: #eef2ff;
          }
        }
      }
    }
  }
}
