@import '../../../sass/vars';

#main-modal-cta {
  @extend %cover;

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 150;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  @media (max-height: 720px) {
    padding: 0;
  }
  @media (max-height: 650px) or (max-width: 1023px) {
    overflow: auto;
    padding: 60px 0;
    display: block;
    align-items: unset;
    justify-content: unset;
  }
  @media (max-width: 1023px) {
    padding: 0;
  }

  .privacy {
    margin-top: 15px;
    font-size: 12px;
    color: #7785b5;

    a {
      text-decoration: none;
    }
  }

  .background {
    @extend %cover;

    background-color: fade-out(black, 0.4);
    position: fixed;
    z-index: 1;
  }

  .wrapper {
    background-color: #eef2ff;
    position: relative;
    width: 980px;
    padding: 40px;
    border-radius: 8px;
    z-index: 2;

    @media (max-height: 720px) {
      padding: 25px 35px;
    }
    @media (max-height: 650px) or (max-width: 1023px) {
      margin: 0 auto;
    }
    @media (max-width: 1023px) {
      width: 100%;
      padding: 35px 25px;
      border-radius: 0px;
    }

    &-close {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 5;

      @media (max-width: 1023px) {
        top: 42px;
      }
    }

    &-title {
      color: #2e63f9;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.02em;

      @media (max-width: 1023px) {
        font-size: 24px;
      }
    }

    &-description {
      width: 540px;
      margin-top: 15px;
      position: relative;

      @media (max-width: 1023px) {
        width: 100%;
      }

      p {
        color: #7785b5;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4;
        opacity: 0.8;

        @media (max-width: 1023px) {
          font-size: 14px;
        }
      }
    }

    &-form {
      margin-top: 40px;

      @media (max-height: 720px) {
        margin-top: 25px;
      }

      .field-group {
        position: relative;

        .label {
          margin-bottom: 8px;
          color: #7785b5;
          font-size: 10px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: 0.08em;
        }

        .field {
          background-color: white;
          color: #7785b5;
          font-size: 14px;
          font-weight: 600;
          width: 100%;
          height: 44px;
          padding: 0 16px;
          border: 1px solid transparent;
          border-radius: 8px;
          outline: none;
          transition-property: color, background-color, border-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &::placeholder {
            color: fade-out(#7785b5, 0.6);
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &:focus {
            border-color: #2e63f9;
          }
        }

        select.field {
          appearance: none;
          color: fade-out(#7785b5, 0.6);
        }

        textarea.field {
          padding: 16px;
          min-height: 160px;
          resize: none;
        }

        svg {
          position: absolute;
          bottom: 16px;
          right: 19px;
          pointer-events: none;
        }

        &.invalid .field {
          background-color: fade-out(red, 0.8);
          color: fade-out(black, 0.4);

          &::placeholder {
            color: fade-out(black, 0.4);
          }
        }
      }

      .form-flex {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-bottom: 20px;

        @media (max-width: 767px) {
          grid-template-columns: repeat(1, 1fr);
          grid-column-gap: 0;
        }
      }

      .form-submit {
        margin-top: 20px;
        text-align: center;

        button {
          background-image: linear-gradient(90deg, #2e63f9 0%, #1997ff 101.58%);
          border-radius: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          padding: 0 50px;

          span {
            margin-right: 10px;
            color: white;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0.08em;
            text-transform: uppercase;
          }

          svg {
            transition-property: transform;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
          }

          &:hover {
            svg {
              transform: translate(3px, -3px);
            }
          }
        }
      }
    }
  }
}
