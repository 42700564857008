@import '../../../../sass/vars';

#page-case .section-hero {
  position: relative;
  width: 100%;
  padding: 0 0 50px;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 525px;
    z-index: 1;

    @media (max-width: 1023px) {
      height: 350px;
    }

    img {
      @extend %cover;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    padding: 200px 165px 0 70px;

    @media (max-width: 1023px) {
      padding: 260px 0 0;
    }

    .left {
      padding-top: 210px;

      @media (max-width: 1023px) {
        padding: 0;
      }

      &-return {
        width: 365px;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        @media (max-width: 1023px) {
          width: 100%;
        }

        a {
          display: flex;
          align-items: center;

          span {
            color: #ffc666;
            font-size: 12px;
            font-weight: 800;
            text-transform: uppercase;
            display: inline-block;
            letter-spacing: 0.08em;
            margin-left: 10px;
          }
        }
      }

      &-logo {
        background-color: #eef2ff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 365px;
        height: 155px;
        border-radius: 80px;

        @media (max-width: 1023px) {
          width: 100%;
          height: 100px;
        }

        picture {
          display: block;
          max-width: 90%;
          max-height: 90%;

          @media (max-width: 1023px) {
            max-width: 50%;
            max-height: 70%;
          }

          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      &-title {
        color: #2e63f9;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.2;
        width: 405px;
        margin-top: 40px;
        margin-left: 50px;

        @media (max-width: 1023px) {
          font-size: 22px;
          width: 100%;
          margin: 30px 0 0;
        }
      }

      &-description {
        width: 480px;
        margin-top: 20px;
        margin-left: 50px;

        @media (max-width: 1023px) {
          width: 100%;
          margin: 15px 0 0;
        }

        p {
          background: linear-gradient(90deg, #2e63f9 0%, #aa83ff 101.58%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          color: #2e63f9;
          font-size: 20px;
          font-weight: normal;
          line-height: 1.4;
          display: inline-block;

          @media (max-width: 1023px) {
            font-size: 16px;
          }
        }
      }
    }

    .right {
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }
}
