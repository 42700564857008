@import '../../../../sass/vars';

#root-home .section-motives {
  position: relative;
  padding: 140px 0;
  background-color: #eef2ff;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .background {
    position: absolute;
    top: 75px;
    left: calc(50% + 120px);
    pointer-events: none;
    width: 730px;
    height: 878px;
    z-index: 1;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .headline {
    &-title {
      background-image: linear-gradient(to right, #2e63f9, #aa83ff);
      font-size: 32px;
      font-weight: 700;
      letter-spacing: -0.02em;
      display: inline-block;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      padding-bottom: 5px;

      @media (max-width: 1023px) {
        font-size: 24px;
      }

      strong {
        background-image: linear-gradient(to right, #00289a, #00289a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    &-descriptions {
      margin-top: 50px;
      display: flex;
      align-items: flex-start;

      @media (max-width: 767px) {
        flex-direction: column;
        margin-top: 30px;
      }

      .description {
        width: 330px;

        @media (max-width: 767px) {
          width: 100%;
        }

        &:not(:last-child) {
          margin-right: 65px;

          @media (max-width: 1023px) {
            margin: 0 0 30px;
          }
        }

        p {
          color: #7785b5;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.6;
          opacity: 0.8;

          strong {
            color: #00289a;
            font-weight: 800;
          }
        }
      }
    }
  }

  .flex-wrapper {
    margin-top: 50px;
    width: 850px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;

    @media (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .card {
      padding: 32px;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 20px 40px rgba(0, 40, 154, 0.12);
      backdrop-filter: blur(12.5px);
      border-radius: 8px;

      &-icon {
        position: relative;
        width: 40px;
        height: 50px;
      }

      &-description {
        margin-top: 10px;

        p {
          color: #7785b5;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.4;
        }
      }
    }
  }

  .cta {
    margin-top: 50px;

    a {
      border-radius: 8px;
      border: 2px solid #2e63f9;
      background-color: transparent;
      padding: 0 24px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      transition-property: background-color;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);

      span {
        margin-right: 10px;
        color: #00289a;
        font-size: 12px;
        font-weight: 800;
        letter-spacing: 0em;
        text-transform: uppercase;
        transition-property: color;
        transition-duration: time(fast);
        transition-timing-function: ease(inout);
      }

      svg {
        transition-property: transform;
        transition-duration: time(fast);
        transition-timing-function: ease(inout);

        path {
          transition-property: fill;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);
        }
      }

      @media (min-width: 1024px) {
        &:hover {
          background-color: #2e63f9;

          span {
            color: white;
          }

          svg {
            transform: translate(3px, -3px);

            path {
              fill: white;
            }
          }
        }
      }
    }
  }
}
