@import '../../../../sass/vars';

#root-home .section-cases {
  background: linear-gradient(to bottom, white, #eef2ff);
  position: relative;
  padding: 270px 0 190px;
  z-index: 1;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .block {
    position: relative;
  }

  .headline {
    position: relative;
    z-index: 1;

    &-title {
      pointer-events: none;
      user-select: none;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .slide {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -50px;
    z-index: 2;

    @media (max-width: 1023px) {
      width: 100%;
      margin: 0;
    }

    &-items {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: 960px;

      @media (max-width: 1023px) {
        flex-shrink: 0;
        width: 100vw;
        padding: 0 25px 10px;
        overflow-x: auto;
      }

      .item {
        display: flex;
        align-items: center;
        position: relative;
        width: 960px;
        height: 500px;
        margin: 0 auto;
        flex-shrink: 0;
        padding-left: 60px;
        transition-property: transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
        z-index: 1;

        @for $i from 0 to 5 {
          &:nth-child(#{$i}) {
            transition-delay: 0.02s * $i;
          }
        }

        @media (max-width: 1023px) {
          align-items: flex-end;
          width: 100%;
          max-width: 80vw;
          height: auto;
          aspect-ratio: 1 / 1.3;
          padding: 0;
        }

        &:not(:last-child) {
          margin-right: 120px;

          @media (max-width: 1023px) {
            margin-right: 30px;
          }
        }

        &-background {
          @extend %cover;

          overflow: hidden;
          border-radius: 8px;
          z-index: 1;

          &::after {
            @extend %cover;

            content: '';
            background-image: linear-gradient(
              to top,
              fade-out(#292936, 0.55),
              fade-out(#292936, 0.8)
            );
            z-index: 2;
          }

          img {
            @extend %cover;

            z-index: 1;
          }
        }

        &-image {
          position: absolute;
          top: 50%;
          right: 60px;
          width: 196px;
          transform: translateY(-50%);
          filter: drop-shadow(20px 20px 64px rgba(41, 54, 90, 0.5));
          z-index: 2;

          @media (max-width: 1023px) {
            display: none;
          }

          picture,
          img {
            display: block;
            max-width: 100%;
          }
        }

        &-content {
          width: 355px;
          z-index: 3;

          @media (max-width: 767px) {
            width: 100%;
            padding: 25px;
          }

          &--brand {
            height: 70px;

            @media (max-width: 1400px) {
              height: 65px;
            }

            img {
              display: block;
            }
          }

          &--title {
            color: white;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: -0.02em;

            @media (max-width: 1400px) {
              font-size: 18px;
            }
          }

          &--description {
            margin: 20px 0 0;

            @media (max-width: 1023px) {
              display: none;
            }

            p {
              color: white;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.4;
            }
          }

          &--flex {
            display: flex;
            align-items: center;
            margin-top: 15px;

            @media (max-width: 1023px) {
              display: none;
            }

            .left {
              margin-right: 10px;
            }

            .right {
              &-title,
              &-subtitle {
                color: white;
                font-size: 12px;
                font-weight: 600;
              }

              &-subtitle {
                margin-top: 5px;
                font-weight: 400;
              }
            }
          }

          &--cta {
            margin-top: 30px;

            @media (max-width: 1023px) {
              margin-top: 20px;
            }

            a {
              background-color: white;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 50px;
              padding: 0 24px;
              border: 1px solid white;
              border-radius: 8px;
              transition-property: background-color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              span {
                color: #00289a;
                font-size: 12px;
                font-weight: 800;
                letter-spacing: 0.08em;
                margin-right: 10px;
                text-transform: uppercase;
                transition-property: color;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              svg {
                transition-property: transform;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                path {
                  transition-property: fill;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }

              &:hover {
                background-color: transparent;

                span {
                  color: white;
                }

                svg {
                  transform: translate(3px, -3px);

                  path {
                    fill: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    &-arrows {
      position: absolute;
      left: 50%;
      bottom: -90px;
      transform: translateX(-50%);
      width: 960px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 1023px) {
        display: none;
      }

      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        border: 1px solid #becaed;
        border-radius: 50%;
        transition-property: background-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        @media (max-width: 767px) {
          width: 30px;
          height: 30px;
        }

        svg path {
          stroke: #ffc666;
          transition-property: stroke;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }

        &:nth-child(2) {
          margin-left: 15px;
        }

        &:hover {
          background-color: #becaed;

          svg path {
            stroke: #2e63f9;
          }
        }
      }
    }
  }
}
