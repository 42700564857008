@import '../../../../sass/vars';

#root-home .section-hero {
  background: linear-gradient(
    102.34deg,
    #00289a 0.24%,
    #2e63f9 36.31%,
    #ffc666 92.95%
  );
  position: relative;
  overflow: hidden;

  .circles {
    @extend %cover;

    user-select: none;
    pointer-events: none;
    overflow: hidden;
    z-index: 1;

    .circle {
      position: absolute;
      top: 50%;
      left: calc(50% + 250px);
      width: 2000px;
      height: 2000px;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
      animation-name: hero_circle;
      animation-duration: 15s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.555, 0.32, 0.205, 0.63);
      border: 2px solid white;
      border-radius: 50%;
      z-index: 2;

      &:nth-child(2) {
        animation-delay: 5s;
      }

      @keyframes hero_circle {
        0% {
          opacity: 0.5;
          transform: translate(-50%, -50%) scale(0);
        }
        100% {
          opacity: 0;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 810px;

    @media (max-width: 1023px) {
      height: auto;
    }

    .left {
      position: relative;
      flex-shrink: 0;
      width: 675px;
      padding-top: 255px;
      z-index: 2;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 130px 0 75px;
      }

      &-title {
        color: white;
        font-size: 40px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: -0.02em;

        @media (max-width: 767px) {
          font-size: 22px;
        }
      }

      &-description {
        width: 460px;
        margin: 25px 0 50px;

        @media (max-width: 767px) {
          width: 100%;
          margin: 15px 0 25px;
        }

        p {
          color: white;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.4;
          opacity: 0.8;

          @media (max-width: 1023px) {
            font-size: 16px;
          }
        }
      }

      &-cta {
        button {
          background-color: white;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          height: 50px;
          padding: 0 20px;
          border: 1px solid white;
          transition-property: background-color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          span {
            color: #00289a;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-right: 10px;
            transition-property: color;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
          }

          svg {
            transition-property: transform;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);

            path {
              transition-property: fill;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);
            }
          }

          @media (min-width: 1024px) {
            &:hover {
              background-color: transparent;

              span {
                color: white;
              }

              svg {
                transform: translate(3px, -3px);

                path {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }

    .right {
      position: relative;
      flex-shrink: 0;
      z-index: 1;
      height: 100%;
      margin-left: -205px;
      display: flex;
      align-items: flex-end;
      pointer-events: none;
      user-select: none;

      @media (max-width: 1023px) {
        display: none;
      }

      &-image {
        position: relative;
        z-index: 1;

        img {
          display: block;
        }
      }

      @keyframes home_hero_elements {
        0% {
          opacity: 0;
          transform: scale(0.8);
        }
        10%,
        28% {
          opacity: 1;
          transform: scale(1);
        }
        33%,
        100% {
          opacity: 0;
          transform: scale(1);
        }
      }

      &-element {
        background-image: linear-gradient(
          97.97deg,
          rgba(255, 255, 255, 0.81) 2.1%,
          rgba(255, 255, 255, 0.4) 100.18%
        );
        position: absolute;
        border: 1px solid fade-out(white, 0.5);
        border-radius: 9px;
        box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(5.20785px);
        padding: 12px 16px;
        z-index: 2;
        opacity: 0;
        transform: scale(0.8);
        animation-name: home_hero_elements;
        animation-duration: 12s;
        animation-iteration-count: infinite;
        animation-iteration-count: ease(inout);

        &.element-1 {
          top: 166px;
          left: 153px;
        }

        &.element-2 {
          bottom: 163px;
          left: 15px;
          animation-delay: 3s;

          .vectors {
            position: absolute;
            top: -100px;
            left: 80px;
            width: 60px;
            height: 91px;

            @keyframes home_hero_vector {
              0% {
                transform: translateY(10px);
                opacity: 0;
              }
              70% {
                transform: translateY(0);
                opacity: 1;
              }
              100% {
                transform: translateY(-10px);
                opacity: 0;
              }
            }

            .vector {
              position: absolute;
              transform: translateY(10px);
              opacity: 0;
              animation-name: home_hero_vector;
              animation-duration: 5s;
              animation-iteration-count: infinite;
              animation-timing-function: ease(inout);

              &:nth-child(1) {
                top: 0;
                right: 0;
                animation-delay: 0.5s;
              }

              &:nth-child(2) {
                top: 10px;
                left: 4px;
                animation-delay: 0.4s;

                img {
                  transform: rotate(-60deg);
                }
              }

              &:nth-child(3) {
                top: 30px;
                right: 5px;
                animation-delay: 0.3s;

                img {
                  opacity: 0.8;
                  transform: scale(0.8);
                }
              }

              &:nth-child(4) {
                top: 40px;
                right: 27px;
                animation-delay: 0.2s;

                img {
                  transform: scale(0.8) rotate(-60deg);
                  opacity: 0.8;
                }
              }

              &:nth-child(5) {
                right: 5px;
                bottom: 5px;
                animation-delay: 0.1s;

                img {
                  transform: scale(0.6);
                  opacity: 0.6;
                }
              }

              &:nth-child(6) {
                right: 21px;
                bottom: -4px;

                img {
                  transform: scale(0.6) rotate(-60deg);
                  opacity: 0.6;
                }
              }
            }
          }
        }

        &.element-3 {
          bottom: 45px;
          left: 370px;
          animation-delay: 6s;
        }

        &.element-4 {
          right: -57px;
          bottom: 258px;
          animation-delay: 9s;
        }

        &.element-5 {
          right: 0px;
          bottom: 398px;
          animation-delay: 12s;
        }
      }
    }
  }
}
