@import '../../../../sass/vars';

#root-home .section-numbers {
  position: relative;
  background-color: #eef2ff;
  padding: 130px 0 90px;
  z-index: 1;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:nth-child(1) {
        width: 325px;

        @media (max-width: 1023px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }

      &:nth-child(2) {
        width: 320px;

        @media (max-width: 1023px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }

      &:nth-child(3) {
        width: 285px;

        @media (max-width: 1023px) {
          width: 100%;
        }
      }

      &-counter {
        background-image: linear-gradient(to right, #2e63f9, #1997ff);
        color: #2e63f9;
        font-size: 128px;
        font-weight: 400;
        letter-spacing: -0.04em;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        padding: 0 5px;

        @media (max-width: 1023px) {
          font-size: 64px;
        }

        small {
          font-size: 64px;

          @media (max-width: 1023px) {
            font-size: 38px;
          }
        }
      }

      &-description {
        max-width: 100%;
        margin-top: 10px;

        p {
          color: #7785b5;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.4;

          strong {
            font-weight: 600;
          }
        }
      }
    }
  }
}
