@import '../../../../sass/vars';

#page-blog .section-newsletter {
  background-image: linear-gradient(
    102.34deg,
    #00289a 0.24%,
    #2e63f9 36.27%,
    #ffc666 100%
  );
  position: relative;

  .background {
    @extend %cover;

    pointer-events: none;
    user-select: none;
    z-index: 1;

    @media (max-width: 1023px) {
      display: none;
    }

    img {
      @extend %cover;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 515px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      padding: 75px 0;
    }

    .left {
      width: 360px;

      @media (max-width: 1023px) {
        width: 100%;
      }

      &-title {
        color: #ffc666;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.02em;

        @media (max-width: 1023px) {
          font-size: 28px;
        }
      }

      &-description {
        margin-top: 25px;

        @media (max-width: 1023px) {
          margin-top: 15px;
        }

        p {
          color: white;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.4;
          opacity: 0.8;
        }
      }
    }

    .right {
      width: 720px;

      @media (max-width: 1200px) {
        width: 550px;
      }
      @media (max-width: 1023px) {
        width: 100%;
        margin-top: 50px;
      }

      &-form {
        background-color: white;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border-radius: 8px;
        width: 100%;
        padding: 55px 0;

        @media (max-width: 1023px) {
          flex-direction: column;
          align-items: center;
          padding: 30px 20px;
        }

        .field-group {
          width: 430px;

          @media (max-width: 1200px) {
            width: 300px;
          }
          @media (max-width: 1023px) {
            width: 100%;
          }

          .label {
            color: #7785b5;
            font-size: 10px;
            font-weight: 800;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .field {
            background-color: #eef2ff;
            color: #7785b5;
            font-size: 14px;
            font-weight: 600;
            width: 100%;
            height: 44px;
            padding: 0 15px;
            border: none;
            border: 1px solid transparent;
            border-radius: 8px;
            transition-property: color, background-color, border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &::placeholder {
              color: fade-out(#7785b5, 0.6);
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:focus {
              border-color: #2e63f9;
            }
          }

          &.invalid .field {
            background-color: fade-out(red, 0.8);
            color: fade-out(black, 0.4);

            &::placeholder {
              color: fade-out(black, 0.4);
            }
          }
        }

        .form-submit {
          margin-left: 30px;

          @media (max-width: 1023px) {
            width: 100%;
            margin: 20px 0 0;
          }

          button {
            background-image: linear-gradient(
              to right,
              #2e63f9,
              #1997ff,
              #2e63f9
            );
            background-size: 200% 100%;
            background-position: left center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 155px;
            height: 50px;
            border-radius: 8px;
            transition-property: background-position;
            transition-duration: time(fast);
            transition-timing-function: eae(inout);

            @media (max-width: 1023px) {
              width: 100%;
            }

            span {
              color: white;
              font-size: 12px;
              font-weight: 800;
              display: inline-block;
              text-transform: uppercase;
              margin-right: 10px;
            }

            svg {
              transition-property: transform;
              transition-duration: time(fast);
              transition-timing-function: eae(inout);
            }

            @media (min-width: 1024px) {
              &:hover {
                background-position: right center;

                svg {
                  transform: translate(5px, -5px);
                }
              }
            }
          }
        }
      }
    }
  }
}
