@import '../../../../sass/vars';

#root-home .section-functionalities {
  background: linear-gradient(
    102.34deg,
    #00289a 0.24%,
    #2e63f9 37.65%,
    #ffc666 100%
  );
  position: relative;
  padding: 160px 0 100px;
  margin-top: -55px;
  overflow: hidden;
  z-index: 1;

  @media (max-width: 1023px) {
    padding: 120px 0 75px;
  }

  .background {
    position: absolute;
    bottom: 0;
    left: calc(50% - 485px);
    mix-blend-mode: soft-light;
    z-index: 1;

    @media (max-width: 1023px) {
      display: none;
    }

    img {
      display: block;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .headline {
    &-small {
      color: #ffc666;
      font-size: 12px;
      font-weight: 800;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-bottom: 24px;

      @media (max-width: 1023px) {
        margin-bottom: 20px;
      }
    }

    &-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--title {
        width: 855px;
        color: white;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.02em;

        @media (max-width: 767px) {
          width: 100%;
          font-size: 22px;
        }
      }

      &--arrows {
        @media (max-width: 1023px) {
          display: none;
        }

        button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          border: 1px solid white;
          border-radius: 50%;
          transition-property: background-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          @media (max-width: 767px) {
            width: 30px;
            height: 30px;
          }

          svg path {
            stroke: #ffc666;
            transition-property: stroke;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &:nth-child(2) {
            margin-left: 15px;
          }

          &:hover {
            background-color: white;

            svg path {
              stroke: #7785b5;
            }
          }
        }
      }
    }
  }

  .slide {
    display: flex;
    justify-content: flex-start;
    margin-top: 60px;

    @media (max-width: 1023px) {
      margin-top: 50px;
    }

    &-items {
      display: flex;

      @media (max-width: 1023px) {
        position: relative;
        left: -25px;
        width: 100vw;
        overflow-x: auto;
        padding: 0 25px 50px;
        flex-shrink: 0;
      }

      .item {
        position: relative;
        width: 480px;
        flex-shrink: 0;
        transition-property: transform;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          display: flex;
          max-width: 75vw;
        }

        @for $i from 0 to 15 {
          &:nth-child(#{$i}) {
            transition-delay: 0.02s * $i;
          }
        }

        &:not(:last-child) {
          margin-right: 60px;

          @media (max-width: 1023px) {
            margin-right: 30px;
          }
        }

        .card {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          min-height: 100%;

          @media (max-width: 767px) {
            min-height: auto;
            height: auto;
            aspect-ratio: 1 / 1;
          }

          &-link {
            @extend %cover;

            z-index: 5;
          }

          &-block {
            flex-shrink: 0;
            position: relative;
            width: 100%;
            height: 225px;
            border-radius: 8px 8px 0 0;
            overflow: hidden;
            z-index: 1;

            @media (max-width: 767px) {
              height: auto;
              aspect-ratio: 1 / 0.57;
            }
          }

          &-image {
            @extend %cover;

            transition-property: transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            z-index: 1;

            img {
              @extend %cover;
            }
          }

          &-content {
            flex-grow: 1;
            position: relative;
            padding: 40px 30px 55px;
            background-color: white;
            border-radius: 0 0 8px 8px;

            @media (max-width: 1023px) {
              padding: 20px 20px 55px;
            }

            &--button {
              position: absolute;
              top: 25px;
              right: 30px;
              z-index: 2;

              button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #eef2ff;
                width: 56px;
                height: 56px;
                border-radius: 50%;
              }
            }

            &--title {
              color: #2e63f9;
              font-size: 24px;
              font-weight: 700;
              letter-spacing: -0.02em;

              @media (max-width: 1023px) {
                font-size: 21px;
              }
            }

            &--description {
              margin-top: 10px;
              width: 100%;
              max-width: 335px;

              @media (max-width: 767px) {
                width: 100%;
                margin-top: 15px;
              }

              p {
                color: #7785b5;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.6;
              }
            }

            &--items {
              margin-top: 25px;
              width: 100%;
              max-width: 365px;

              @media (max-width: 767px) {
                width: 100%;
                margin-top: 20px;
              }

              ul {
                display: flex;
                flex-wrap: wrap;

                @media (max-width: 767px) {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  grid-column-gap: 15px;
                  grid-row-gap: 5px;
                }

                li {
                  color: #7785b5;
                  font-size: 14px;
                  font-weight: 600;
                  letter-spacing: -0.02em;
                  margin-bottom: 10px;

                  @media (max-width: 1023px) {
                    line-height: 1.4;
                    margin-bottom: 10px;
                  }
                  @media (max-width: 767px) {
                    margin-bottom: 0;
                  }
                  @media (max-width: 355px) {
                    font-size: 12px;
                  }

                  &:not(:last-child) {
                    margin-right: 25px;

                    @media (max-width: 1023px) {
                      margin-right: 15px;
                    }
                    @media (max-width: 767px) {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }

          &-cta {
            position: absolute;
            bottom: -25px;
            left: 35px;
            z-index: 3;

            @media (max-width: 1023px) {
              left: 20px;
            }

            a {
              background-image: linear-gradient(
                to right,
                #2e63f9,
                #1997ff,
                #2e63f9
              );
              background-size: 200% 100%;
              background-position: left center;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              width: 140px;
              height: 50px;
              border-radius: 8px;
              transition-property: background-position;
              transition-duration: time(fast);
              transition-timing-function: eae(inout);

              span {
                color: white;
                font-size: 12px;
                font-weight: 800;
                display: inline-block;
                text-transform: uppercase;
                margin-right: 10px;
              }

              svg {
                transition-property: transform;
                transition-duration: time(fast);
                transition-timing-function: eae(inout);
              }

              &:hover {
                background-position: right center;

                svg {
                  transform: translate(5px, -5px);
                }
              }
            }
          }

          &:hover {
            .card-image {
              transform: scale(1.05);
            }

            .card-cta a {
              background-position: right center;

              svg {
                transform: translate(5px, -5px);
              }
            }
          }
        }
      }
    }
  }
}
