@import '../../../../sass/vars';

#page-blog-single .section-content {
  position: relative;
  padding: 0 0 85px;
  margin-top: -190px;
  z-index: 2;

  @media (max-width: 1023px) {
    padding: 0 0 75px;
    margin: -30px 0 0;
  }

  .headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      &--category {
        margin-right: 20px;
        border-radius: 80px;
        padding: 4px 21px;
        background-color: #ffc666;
        color: black;
        font-size: 12px;
        font-weight: 800;
        letter-spacing: 0.08em;
        text-transform: uppercase;

        @media (max-width: 1023px) {
          font-size: 10px;
          padding: 5px 15px;
        }
      }

      &--date {
        color: #292936;
        font-size: 12px;
        font-weight: 800;
        letter-spacing: 0.08em;
        text-transform: uppercase;

        @media (max-width: 1023px) {
          font-size: 10px;
        }
      }
    }

    &-title {
      color: #2e63f9;
      font-size: 52px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.02em;
      width: 800px;

      @media (max-width: 1023px) {
        width: 100%;
        font-size: 32px;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
    margin: 40px auto 0;

    @media (max-width: 1023px) {
      width: 100%;
      margin-top: 30px;
    }

    > * {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    p,
    ul li {
      color: #7785b5;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5;
      opacity: 0.8;

      @media (max-width: 1023px) {
        font-size: 16px;
      }

      strong {
        color: #2e63f9;
        text-decoration: underline;
      }
    }

    ul li {
      color: #2e63f9;
      position: relative;
      padding-left: 15px;

      &::before {
        content: '';
        background-color: #2e63f9;
        position: absolute;
        top: 11px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }

    blockquote {
      background: linear-gradient(
        102.34deg,
        #00289a 0.24%,
        #2e63f9 36.31%,
        #ffc666 92.95%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.4;
      font-style: italic;
      opacity: 0.8;

      @media (max-width: 1023px) {
        font-size: 22px;
      }

      p {
        background: linear-gradient(
          102.34deg,
          #00289a 0.24%,
          #2e63f9 36.31%,
          #ffc666 92.95%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 28px;
        font-weight: 400;
        line-height: 1.4;
        font-style: italic;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    img {
      width: auto;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
      max-width: 1065px;
      border-radius: 8px;
      display: block;
      height: auto;

      @media (max-width: 1023px) {
        left: 0;
        transform: unset;
        max-width: 100%;
        border-radius: 0px;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #2e63f9;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.02em;

      @media (max-width: 1023px) {
        font-size: 22px;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}
