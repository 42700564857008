@import '../../../../sass/vars';

#root-products .section-hero {
  position: relative;

  .circles {
    @extend %cover;

    user-select: none;
    pointer-events: none;
    overflow: hidden;
    z-index: 2;

    .circle {
      position: absolute;
      top: 50%;
      left: 30%;
      width: 2000px;
      height: 2000px;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
      animation-name: hero_circle;
      animation-duration: 15s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.555, 0.32, 0.205, 0.63);
      border: 2px solid white;
      border-radius: 50%;
      z-index: 5;

      &:nth-child(2) {
        animation-delay: 5s;
      }

      @keyframes hero_circle {
        0% {
          opacity: 0.5;
          transform: translate(-50%, -50%) scale(0);
        }
        100% {
          opacity: 0;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  .background {
    @extend %cover;

    pointer-events: none;
    user-select: none;
    z-index: 1;

    img {
      @extend %cover;
    }
  }

  .container {
    position: relative;
    z-index: 3;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 140px;
    height: 605px;

    @media (max-width: 1023px) {
      flex-direction: column;
      height: 450px;
      padding: 0;
    }

    .left {
      position: relative;
      flex-shrink: 0;
      margin-right: 15px;
      width: 580px;
      height: 100%;
      padding-top: 90px;
      text-align: center;

      @media (max-width: 1023px) {
        width: 100%;
        margin: 0;
        padding: 130px 0 0;
      }

      &-title {
        p {
          color: white;
          font-size: 36px;
          font-weight: 700;
          line-height: 1.2;
          letter-spacing: -0.02em;

          @media (max-width: 767px) {
            font-size: 22px;
          }

          strong {
            color: #ffc666;
          }
        }
      }

      &-description {
        width: 500px;
        margin: 25px auto 0;

        @media (max-width: 1023px) {
          width: 100%;
        }

        p {
          color: white;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.4;

          @media (max-width: 1023px) {
            font-size: 16px;
          }
        }
      }

      &-cta {
        position: absolute;
        left: 50%;
        bottom: -25px;
        transform: translateX(-50%);

        @media (max-width: 1023px) {
          white-space: nowrap;
        }

        button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          box-shadow: 0px 24px 64px rgba(46, 99, 249, 0.7);
          border-radius: 8px;
          height: 50px;
          padding: 0 24px;
          border: 1px solid white;
          transition-property: background-color, border-color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          span {
            margin-right: 10px;
            color: #00289a;
            font-size: 12px;
            font-weight: 800;
            line-height: 15px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            transition-property: color;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);

            @media (max-width: 767px) {
              text-align: center;
            }
          }

          svg {
            transition-property: transform;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);

            path {
              transition-property: fill;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);
            }
          }

          &:hover {
            background-color: #2e63f9;
            border-color: #2e63f9;

            span {
              color: white;
            }

            svg {
              transform: translate(3px, -3px);

              path {
                fill: white;
              }
            }
          }
        }
      }
    }

    .right {
      position: relative;
      flex-shrink: 0;
      user-select: none;
      pointer-events: none;
      z-index: 2;

      @media (max-width: 1023px) {
        display: none;
      }
    }
  }
}
