#root {
  position: relative;
  width: 100%;
  height: 100%;

  .page-transition {
    position: relative;
    width: 100%;
    height: 100%;

    .root-page {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &.transition-enter {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
      }

      &.transition-enter-done,
      &.transition-exit {
        transition-property: opacity;
        transition-duration: time(slow);
        transition-timing-function: ease(inout);
      }

      &.transition-enter-active {
        opacity: 0;
      }

      &.transition-enter-done {
        opacity: 1;
      }

      &.transition-exit {
        opacity: 0;
      }

      .page-transform {
        will-change: transform;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        z-index: 10;
      }
    }
  }
}
