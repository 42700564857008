[rel='lazy_load'],
[rel='lazy_load_new'] {
  overflow: hidden;

  &:not(.no-animate) {
    img {
      z-index: 1;
      transform-origin: 25% 25%;
      opacity: 0;
      transform: scale(1.05);
      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ease(inout);
    }
  }

  &.no-animate img {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.8s;
    transition-timing-function: ease(inout);
  }

  &.loaded {
    &:not(.no-animate) {
      img {
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.7s;
      }
    }

    &.no-animate img {
      opacity: 1;
    }
  }
}
