@import '../../../sass/vars';

#main-preloader {
  @extend %cover;

  background-color: white;
  position: fixed;
  transition-property: opacity;
  transition-duration: time(slow);
  transition-timing-function: ease(inout);
  z-index: 55;

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 86px;
    height: 32px;
    transform: translate(-50%, -50%) scale(1.5);
    transition-property: opacity, transform;
    transition-duration: time(default);
    transition-timing-function: ease(inout);
    z-index: 1;

    &-bar {
      content: '';
      background-color: #292936;
      position: absolute;
      top: 0;
      left: 31px;
      width: 24px;
      height: 6px;
      border-radius: 2px;
      transform-origin: 0% 50%;
      transform: scaleX(0);
      transition-property: transform;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);
      transition-delay: 0.15s;
      z-index: 1;
    }

    svg {
      display: inline-block;
      overflow: visible;
      opacity: 0;
      transform: translateX(10px);
      transition-property: opacity, transform;
      transition-duration: time(default);
      transition-timing-function: ease(inout);

      &:not(:last-child) {
        margin-right: 2px;
      }

      &:nth-child(1) {
        transition-delay: 0s;
      }

      &:nth-child(2) {
        transition-delay: 0.05s;
      }

      &:nth-child(3) {
        transition-delay: 0.1s;
      }
    }
  }

  &.active {
    .logo {
      &-bar {
        transform: scaleX(1);
      }

      svg {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &.remove {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0.3s;

    .logo {
      opacity: 0;
      transform: translate(calc(-50% - 10px), -50%) scale(1.5);
    }
  }
}
