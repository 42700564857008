@import '../../../../sass/vars';

#page-product .section-content {
  padding: 120px 0 210px;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .items {
    .item {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &:not(:last-child) {
        margin-bottom: 110px;
      }

      @for $i from 0 to 5 {
        &:nth-child(#{$i}) {
          .item-left::before {
            animation-delay: 0.5s * $i;
          }
        }
      }

      @media (min-width: 1024px) {
        &:nth-child(odd) {
          @media (max-width: 767px) {
            display: unset;
          }

          .item-left {
            order: 1;
            margin-right: 95px;

            @media (max-width: 767px) {
              margin-right: 0;
            }
          }

          .item-right {
            order: 2;
          }
        }

        &:nth-child(even) {
          @media (max-width: 767px) {
            display: unset;
          }

          .item-left {
            order: 2;
            margin-left: 95px;

            @media (max-width: 767px) {
              margin-left: 0;
            }
          }

          .item-right {
            order: 1;
          }
        }
      }

      &-left {
        position: relative;
        flex-shrink: 0;
        z-index: 1;

        @keyframes product_content_before {
          0% {
            transform: scale(0.5) translate(-50%, -50%);
          }
          100% {
            transform: scale(1) translate(-50%, -50%);
          }
        }

        &::before {
          content: '';
          background-image: linear-gradient(
            40deg,
            rgba(255, 174, 38, 0.1) -4.08%,
            rgba(149, 207, 255, 0.1) 103.38%
          );
          width: 520px;
          height: 520px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: 0% 0%;
          transform: scale(0.5);
          animation-name: product_content_before;
          animation-duration: 3s;
          animation-direction: alternate;
          animation-iteration-count: infinite;
          animation-timing-function: ease(inout);
          z-index: 1;

          @media (max-width: 767px) {
            width: 400px;
            height: 400px;
          }
        }

        &--image {
          position: relative;
          z-index: 2;

          img {
            @media (max-width: 1023px) {
              max-width: 100%;
            }
          }
        }
      }

      &-right {
        width: 350px;

        @media (max-width: 767px) {
          width: 100%;
        }

        &--title {
          background: linear-gradient(90deg, #2e63f9 0%, #aa83ff 101.58%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-size: 32px;
          font-weight: 700;
          line-height: 1.2;
          letter-spacing: -0.02em;
          display: inline-block;

          @media (max-width: 767px) {
            font-size: 24px;
          }
        }

        &--description {
          margin-top: 20px;

          @media (max-width: 767px) {
            margin-top: 15;
          }

          p {
            color: #7785b5;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.6;
            opacity: 0.8;
          }
        }
      }
    }
  }
}
