@import '../../../sass/vars';

#main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 0;
  transition-property: padding, background-color;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 50;

  @media (max-width: 1023px) {
    padding: 0;
  }

  .flex-wrapper {
    background-image: linear-gradient(
      97.97deg,
      rgba(255, 255, 255, 0.9) 2.1%,
      rgba(255, 255, 255, 0.6) 100.18%
    );
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5.4881px);
    padding: 15px 20px 15px 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1023px) {
      padding: 20px 25px;
      border-radius: 0px;
    }

    .right {
      display: flex;
      align-items: center;

      &-menu {
        display: none;

        @media (max-width: 1023px) {
          display: block;
        }
      }

      &-navigation {
        margin-right: 130px;

        @media (max-width: 1023px) {
          display: none;
        }

        ul {
          display: flex;
          align-items: center;

          li {
            &:not(:last-child) {
              margin-right: 65px;
            }

            a {
              color: #2e63f9;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0.02em;
              transition-property: color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              &:hover {
                color: black;
              }
            }
          }
        }
      }

      &-cta {
        @media (max-width: 1023px) {
          display: none;
        }

        button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          padding: 0 24px;
          border: 2px solid #2e63f9;
          border-radius: 8px;
          color: #2e63f9;
          font-size: 10px;
          font-weight: 800;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          transition-property: background-color, color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          &:hover {
            background-color: #2e63f9;
            color: white;
          }
        }
      }
    }
  }
}

#main-header .menu-mobile {
  @extend %cover;

  background-color: #eef2ff;
  position: fixed;
  opacity: 0;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  pointer-events: none;
  z-index: 100;

  .logo {
    position: absolute;
    top: 20px;
    left: 25px;
    z-index: 2;
  }

  .close {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 2;

    button {
      display: inline-block;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navigation {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;

    ul {
      li {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        &:not(:last-child) {
          margin-bottom: 22px;
        }

        a {
          color: #2e63f9;
          font-size: 32px;
          font-weight: 600;
        }
      }
    }
  }

  .cta {
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      padding: 0 24px;
      border: 2px solid #2e63f9;
      border-radius: 8px;
      color: #2e63f9;
      font-size: 10px;
      font-weight: 800;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      transition-property: background-color, color;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);
    }
  }

  .social {
    position: relative;
    margin-top: 50px;

    ul {
      display: flex;
      align-items: center;

      li {
        opacity: 0;
        transform: translateY(10px);
        transition-property: opacity, transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        &:not(:last-child) {
          margin-right: 50px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;

          svg path {
            transition-property: fill;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
          }

          &:hover svg path {
            fill: black;
          }
        }
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: initial;

    .navigation ul li {
      opacity: 1;
      transform: translateY(0);

      @for $i from 0 to 7 {
        &:nth-child(#{$i}) {
          transition-delay: 0.1s * $i;
        }
      }
    }

    .cta {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.5s;
    }

    .social ul li {
      opacity: 1;
      transform: translateY(0);

      @for $i from 0 to 7 {
        &:nth-child(#{$i}) {
          transition-delay: 0.1s * $i + 0.5s;
        }
      }
    }
  }
}
