@import '../../../../sass/vars';

#page-product .section-hero {
  background: linear-gradient(
    102.34deg,
    #00289a 0.24%,
    #2e63f9 36.31%,
    #ffc666 92.95%
  );
  position: relative;
  z-index: 2;

  .circles {
    @extend %cover;

    user-select: none;
    pointer-events: none;
    overflow: hidden;
    z-index: 4;

    .circle {
      position: absolute;
      top: 50%;
      left: 20%;
      width: 2000px;
      height: 2000px;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
      animation-name: hero_circle;
      animation-duration: 15s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.555, 0.32, 0.205, 0.63);
      border: 2px solid white;
      border-radius: 50%;
      z-index: 5;

      &:nth-child(2) {
        animation-delay: 5s;
      }

      @keyframes hero_circle {
        0% {
          opacity: 0.5;
          transform: translate(-50%, -50%) scale(0);
        }
        100% {
          opacity: 0;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  .container {
    position: relative;
    z-index: 6;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 135px;
    height: 480px;

    @media (max-width: 1023px) {
      height: 350px;
      padding: 0;
    }

    .left {
      position: relative;
      margin-right: 75px;
      padding-top: 65px;
      flex-shrink: 0;
      width: 355px;
      height: 100%;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 130px 0 0;
        margin: 0;
      }

      &-return {
        a {
          span {
            margin-left: 10px;
            color: #ffc666;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0.08em;
            text-transform: uppercase;
          }
        }
      }

      &-title {
        margin: 25px 0;
        color: white;
        font-size: 36px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.02em;

        @media (max-width: 1023px) {
          font-size: 28px;
          margin: 25px 0 15px;
        }
      }

      &-description {
        p {
          color: white;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.4;
          opacity: 0.8;

          @media (max-width: 1023px) {
            font-size: 16px;
          }
        }
      }

      &-cta {
        position: absolute;
        left: 0;
        bottom: -25px;

        button {
          background-color: white;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0 24px;
          height: 50px;
          border-radius: 8px;
          border: 1px solid white;
          box-shadow: 0px 24px 64px rgba(46, 99, 249, 0.7);
          transition-property: background-color, border-color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          span {
            margin-right: 10px;
            color: #00289a;
            font-size: 12px;
            font-weight: 800;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            transition-property: color;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
          }

          svg {
            transition-property: transform;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);

            path {
              transition-property: fill;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);
            }
          }

          @media (min-width: 1024px) {
            &:hover {
              background-color: #2e63f9;
              border-color: #2e63f9;

              span {
                color: white;
              }

              svg {
                transform: translate(3px, -3px);

                path {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }

    .right {
      position: relative;
      display: flex;
      width: 800px;
      height: 345px;
      overflow: hidden;
      flex-shrink: 0;

      @media (max-width: 1023px) {
        display: none;
      }

      &::before {
        content: '';
        background: #2e63f9;
        position: absolute;
        filter: blur(100px);
        width: 1190px;
        height: 220px;
        bottom: -145px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 7;
      }

      &-desktop {
        position: relative;
        width: 690px;
        height: 385px;
        border-radius: 12px;
        overflow: hidden;
        z-index: 1;
      }

      &-phone {
        position: absolute;
        top: 75px;
        right: 0;
        width: 155px;
        height: 345px;
        border-radius: 12px;
        z-index: 2;
      }

      img {
        @extend %cover;
      }
    }
  }
}
