@import '../../../../sass/vars';

#root-cases .section-cases {
  background: linear-gradient(to bottom, white, #eef2ff);
  position: relative;
  z-index: 1;

  .block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--initial-vh, 1vh) * 100);
  }

  .headline {
    background-image: linear-gradient(to right, #2e63f9, #2e63f9);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 315px;
    z-index: 1;

    @media (max-width: 1023px) {
      height: 180px;
    }

    &::before,
    &::after {
      @extend %cover;

      content: '';
      z-index: 2;
    }

    &::before {
      background-image: linear-gradient(
        to right,
        #2e63f9,
        fade-out(#2e63f9, 1) 20%
      );
    }

    &::after {
      background-image: linear-gradient(to top, #1f8afe, fade-out(#1f8afe, 1));
    }

    &-title {
      pointer-events: none;
      user-select: none;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 2;

    @media (max-width: 1023px) {
      width: 100vw;
    }

    &-items {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: 1100px;
      padding-left: 70px;
      z-index: 1;

      @media (max-width: 1400px) {
        width: 940px;
      }
      @media (max-width: 1023px) {
        width: 100%;
        padding: 0 25px;
      }

      .item {
        display: flex;
        align-items: center;
        position: relative;
        width: 960px;
        height: 500px;
        margin: 0 auto;
        flex-shrink: 0;
        padding-left: 60px;
        z-index: 1;

        @media (max-width: 1400px) {
          width: 800px;
          height: 450px;
        }
        @media (max-width: 1023px) {
          align-items: flex-end;
          width: 100%;
          height: auto;
          aspect-ratio: 1 / 1.3;
          padding: 0;
        }

        &:not(:last-child) {
          margin-right: 140px;

          @media (max-width: 1023px) {
            margin-right: 50px;
          }
        }

        &-background {
          @extend %cover;

          overflow: hidden;
          border-radius: 8px;
          z-index: 1;

          &::after {
            @extend %cover;

            content: '';
            background-image: linear-gradient(
              to top,
              fade-out(#292936, 0.55),
              fade-out(#292936, 0.8)
            );
            z-index: 2;
          }

          img {
            @extend %cover;

            z-index: 1;
          }
        }

        &-image {
          position: absolute;
          width: 205px;
          top: 50%;
          transform: translateY(-50%);
          right: 120px;
          filter: drop-shadow(20px 20px 64px rgba(41, 54, 90, 0.5));
          z-index: 2;

          @media (max-width: 1700px) {
            right: 60px;
          }
          @media (max-width: 1400px) {
            width: 175px;
          }
          @media (max-width: 1023px) {
            display: none;
          }

          picture,
          img {
            display: block;
            max-width: 100%;
          }
        }

        &-content {
          width: 355px;
          z-index: 3;

          @media (max-width: 767px) {
            width: 100%;
            padding: 25px;
          }

          &--brand {
            height: 70px;

            @media (max-width: 1400px) {
              height: 65px;
            }

            img {
              display: block;
            }
          }

          &--title {
            color: white;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: -0.02em;

            @media (max-width: 1400px) {
              font-size: 18px;
            }
          }

          &--description {
            margin: 20px 0 0;

            @media (max-width: 1023px) {
              display: none;
            }

            p {
              color: white;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.4;
            }
          }

          &--flex {
            display: flex;
            align-items: center;
            margin-top: 15px;

            @media (max-width: 1023px) {
              display: none;
            }

            .left {
              margin-right: 10px;
            }

            .right {
              &-title,
              &-subtitle {
                color: white;
                font-size: 12px;
                font-weight: 600;
              }

              &-subtitle {
                margin-top: 5px;
                font-weight: 400;
              }
            }
          }

          &--cta {
            margin-top: 30px;

            @media (max-width: 1023px) {
              margin-top: 20px;
            }

            a {
              background-color: white;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 50px;
              padding: 0 24px;
              border: 1px solid white;
              border-radius: 8px;
              transition-property: background-color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              span {
                color: #00289a;
                font-size: 12px;
                font-weight: 800;
                letter-spacing: 0.08em;
                margin-right: 10px;
                text-transform: uppercase;
                transition-property: color;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              svg {
                transition-property: transform;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                path {
                  transition-property: fill;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }

              @media (min-width: 1024px) {
                &:hover {
                  background-color: transparent;

                  span {
                    color: white;
                  }

                  svg {
                    transform: translate(3px, -3px);

                    path {
                      fill: white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &-navigation {
      background-color: white;
      position: absolute;
      bottom: 45px;
      left: 50%;
      transform: translateX(-50%);
      width: 575px;
      height: 54px;
      border-radius: 80px;
      box-shadow: 0px 24px 44px rgba(0, 23, 89, 0.1);
      z-index: 2;

      @media (max-width: 1400px) {
        bottom: 25px;
      }
      @media (max-width: 1023px) {
        width: 90vw;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        li {
          &:not(:last-child) {
            margin-right: 64px;

            @media (max-width: 1023px) {
              margin-right: 40px;
            }
          }

          button {
            color: #94affc;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.02em;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &.active button,
          &:hover button {
            color: #00289a;
          }
        }
      }
    }
  }
}
