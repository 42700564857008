@import '../../../../sass/vars';

#page-case .section-content {
  padding: 100px 0 210px;
  overflow: hidden;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

    @media (max-width: 1023px) {
      flex-direction: column;
      padding: 0;
    }

    .left {
      @media (max-width: 1023px) {
        width: 100%;
      }

      &-slide {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 665px;

        @media (max-width: 1023px) {
          width: 100%;
        }

        .slide-items {
          position: relative;
          width: 100%;
          height: 355px;

          @media (max-width: 1023px) {
            width: 100%;
            height: auto;
            aspect-ratio: 1 / 0.7;
          }

          .item {
            @extend %cover;

            opacity: 0;
            transition-property: opacity;
            transition-duration: time(slow);
            transition-timing-function: ease(inout);
            border-radius: 8px;
            overflow: hidden;
            z-index: 1;

            &.active {
              opacity: 1;
              z-index: 2;

              .item-image {
                display: block;
              }
            }

            &-image,
            &-image img {
              @extend %cover;
            }

            &-image {
              display: none;

              &.loaded {
                display: block;
              }
            }
          }
        }

        .slide-dots {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 24px;

          .dot {
            background-color: #d2ddfe;
            position: relative;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            cursor: pointer;
            transition-property: background-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &:not(:last-child) {
              margin-right: 8px;
            }

            &.active,
            &:hover {
              background-color: #2e63f9;
            }
          }
        }
      }
    }

    .right {
      width: 350px;

      @media (max-width: 1023px) {
        margin: 40px 0 0;
        width: 100%;
      }

      &-description {
        p {
          color: #7785b5;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.6;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .text {
    width: 760px;
    margin: 150px auto 0;
    text-align: center;

    @media (max-width: 1023px) {
      margin: 70px 0 0;
      width: 100%;
      text-align: left;
    }

    p {
      color: #7785b5;
      font-size: 20px;
      font-weight: normal;
      line-height: 1.6;

      @media (max-width: 1023px) {
        font-size: 18px;
      }
    }
  }

  .gallery {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    &-circles {
      @extend %cover;

      user-select: none;
      pointer-events: none;
      z-index: 4;

      .circle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2000px;
        height: 2000px;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
        animation-name: case_content_circle;
        animation-duration: 15s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.555, 0.32, 0.205, 0.63);
        border: 2px solid #94affc;
        border-radius: 50%;
        z-index: 5;

        &:nth-child(2) {
          animation-delay: 5s;
        }

        @keyframes case_content_circle {
          0% {
            opacity: 0.5;
            transform: translate(-50%, -50%) scale(0);
          }
          100% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }

    &-image {
      position: relative;
      z-index: 2;

      img {
        width: 100vw;
        display: block;
      }
    }
  }
}
