.screen-progress {
  background-color: #2e63f9;
  text-align: center;
  border-radius: 4px;
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  margin-top: 15px;
  padding: 30px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: background-color time(fast) ease(fade),
    margin-top time(default) ease(inout), opacity time(default) ease(fade);
  box-shadow: 0 5px 10px fade-out(#eef2ff, 0.9);

  &.open {
    margin-top: 0;
    opacity: 1;
  }

  .screen-message {
    color: #eef2ff;
    font-size: 16px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    transition: color time(fast) ease(fade);
  }

  .spinner {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    span {
      background-color: #eef2ff;
      border-radius: 50%;
      display: block;
      width: 400px;
      height: 400px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -200px 0 0 -200px;
      opacity: 0;
      transform: scale(0);
      animation: spinner_progress 1.5s infinite;

      @keyframes spinner_progress {
        0% {
          transform: scale(0);
          opacity: 0;
        }

        50% {
          opacity: 0.1;
        }

        100% {
          transform: scale(1);
          opacity: 0;
        }
      }

      &:nth-child(2) {
        animation-delay: 0.5s;
      }
    }
  }
}
