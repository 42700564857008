@import '../../../../sass/vars';

#page-blog-single .section-hero {
  background-color: #eef2ff;
  position: relative;
  padding: 135px 0 0;
  z-index: 1;

  @media (max-width: 1023px) {
    padding: 130px 0 0;
  }

  &::before {
    content: '';
    background: linear-gradient(
      102.34deg,
      #00289a 0.24%,
      #2e63f9 36.31%,
      #ffc666 92.95%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    z-index: 1;

    @media (max-width: 1023px) {
      height: auto;
      aspect-ratio: 1 / 0.8;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .wrapper {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    user-select: none;

    &-image {
      width: 100%;
      height: 680px;

      @media (max-width: 1023px) {
        height: auto;
        aspect-ratio: 1 / 0.8;
      }

      &::after {
        @extend %cover;

        content: '';
        background: linear-gradient(
          180deg,
          rgba(238, 242, 255, 0) 40.4%,
          rgba(238, 242, 255, 0.99) 75.35%
        );
        z-index: 2;
      }

      img {
        @extend %cover;
      }
    }
  }
}
