@import '../../../sass/vars';

#main-footer {
  background-color: #eef2ff;
  padding: 90px 0 50px;

  @media (max-width: 1023px) {
    padding: 75px 0 50px;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #2e63f9;
    padding-bottom: 50px;
    margin-bottom: 50px;

    @media (max-width: 1023px) {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .left {
      @media (max-width: 767px) {
        margin: 0 auto;
        text-align: center;
      }

      &-navigation {
        margin-top: 50px;

        @media (max-width: 1023px) {
          display: none;
        }

        ul {
          display: flex;
          align-items: flex-start;

          li {
            &:not(:last-child) {
              margin-right: 65px;

              @media (max-width: 767px) {
                margin-right: 0;
              }
            }

            a {
              color: #7785b5;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: -0.02em;
              transition-property: color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              &:hover {
                color: black;
              }
            }
          }
        }
      }
    }

    .right {
      @media (max-width: 767px) {
        margin: 30px auto 0;
      }

      &-cta {
        @media (max-width: 767px) {
          text-align: center;
        }

        button {
          background-color: transparent;
          border: 1px solid #2e63f9;
          border-radius: 8px;
          height: 44px;
          padding: 0 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #00289a;
          font-size: 10px;
          font-weight: 800;
          text-transform: uppercase;
          transition-property: background-color, color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          @media (min-width: 1024px) {
            &:hover {
              background-color: #2e63f9;
              color: white;
            }
          }
        }
      }

      &-social {
        margin-top: 45px;

        @media (max-width: 1023px) {
          margin-top: 30px;
        }

        ul {
          display: flex;
          align-items: center;

          li {
            &:not(:last-child) {
              margin-right: 50px;
            }

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;

              svg path {
                transition-property: fill;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              &:hover svg path {
                fill: black;
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    text-align: center;
    color: #7785b5;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.02em;

    &.appear {
      opacity: 0.6;
    }
  }
}
