@import '../../../../sass/vars';

#root-home .section-partners {
  position: relative;
  padding: 100px 0 200px;
  overflow: hidden;

  @media (max-width: 1023px) {
    padding: 40px 0;
  }

  @keyframes home_partners_slide_1 {
    0% {
      opacity: 0;
      transform: translateX(0);
    }
    10%,
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(calc(-50% - 15px));
    }
  }

  @keyframes home_partners_slide_2 {
    0% {
      opacity: 0;
      transform: translateX(calc(-50% - 15px));
    }
    10%,
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(0);
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .items {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &:nth-child(1) {
      animation-name: home_partners_slide_1;
      animation-duration: 50s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      margin-bottom: 35px;
    }

    &:nth-child(2) {
      animation-name: home_partners_slide_2;
      animation-duration: 50s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .item {
      &:not(:last-child) {
        margin-right: 30px;
      }

      user-select: none;
      pointer-events: none;

      &-logo {
        position: relative;
        width: 160px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
