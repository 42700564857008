@import '../../../../sass/vars';

#root-products .section-products {
  padding: 165px 0;

  @media (max-width: 1023px) {
    padding: 75px 0;
  }

  .cards {
    padding: 0 100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 50px;

    @media (max-width: 1023px) {
      grid-column-gap: 30px;
      padding: 0;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 70px;
    }

    .item {
      position: relative;
      width: 100%;

      &:nth-child(2n) {
        padding-top: 50px;

        @media (max-width: 1023px) {
          padding: 0;
        }
      }
    }

    .card {
      position: relative;

      &-link {
        @extend %cover;

        z-index: 5;
      }

      &-block {
        position: relative;
        width: 100%;
        height: 225px;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        z-index: 1;

        @media (max-width: 767px) {
          height: auto;
          aspect-ratio: 1 / 0.57;
        }
      }

      &-image {
        @extend %cover;

        transition-property: transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
        z-index: 1;

        img {
          @extend %cover;
        }
      }

      &-content {
        position: relative;
        padding: 40px 30px 55px;
        background-color: white;
        border-radius: 0 0 8px 8px;

        @media (max-width: 1023px) {
          padding: 20px 20px 55px;
        }

        &--button {
          position: absolute;
          top: 25px;
          right: 30px;
          z-index: 2;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #eef2ff;
            width: 56px;
            height: 56px;
            border-radius: 50%;
          }
        }

        &--title {
          color: #2e63f9;
          font-size: 24px;
          font-weight: 700;
          letter-spacing: -0.02em;

          @media (max-width: 1023px) {
            font-size: 21px;
          }
        }

        &--description {
          margin-top: 10px;
          width: 100%;
          max-width: 335px;

          @media (max-width: 767px) {
            width: 100%;
            margin-top: 15px;
          }

          p {
            color: #7785b5;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.6;
          }
        }

        &--items {
          margin-top: 25px;
          width: 100%;
          max-width: 365px;

          @media (max-width: 767px) {
            width: 100%;
            margin-top: 20px;
          }

          ul {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 767px) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 15px;
              grid-row-gap: 5px;
            }

            li {
              color: #7785b5;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: -0.02em;
              margin-bottom: 10px;

              @media (max-width: 1023px) {
                line-height: 1.4;
                margin-bottom: 10px;
              }
              @media (max-width: 767px) {
                margin-bottom: 0;
              }
              @media (max-width: 355px) {
                font-size: 12px;
              }

              &:not(:last-child) {
                margin-right: 25px;

                @media (max-width: 1023px) {
                  margin-right: 15px;
                }
                @media (max-width: 767px) {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }

      &-cta {
        position: absolute;
        top: calc(100% - 25px);
        left: 35px;
        z-index: 3;

        @media (max-width: 1023px) {
          left: 20px;
        }

        a {
          background-image: linear-gradient(
            to right,
            #2e63f9,
            #1997ff,
            #2e63f9
          );
          background-size: 200% 100%;
          background-position: left center;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 140px;
          height: 50px;
          border-radius: 8px;
          transition-property: background-position;
          transition-duration: time(fast);
          transition-timing-function: eae(inout);

          span {
            color: white;
            font-size: 12px;
            font-weight: 800;
            display: inline-block;
            text-transform: uppercase;
            margin-right: 10px;
          }

          svg {
            transition-property: transform;
            transition-duration: time(fast);
            transition-timing-function: eae(inout);
          }
        }
      }

      &:hover {
        .card-image {
          transform: scale(1.05);
        }

        .card-cta a {
          background-position: right center;

          svg {
            transform: translate(5px, -5px);
          }
        }
      }
    }
  }
}
